import React, {useEffect} from 'react';
import Grid from "@mui/material/Grid";
import TooltipIcon from "components/TooltipIcon";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {recursiveFindArray, recursiveFindObject, recursiveFindObjectWithHistory} from "utils/semantic";
import InputLabel from "@mui/material/InputLabel";
import {FormControl, ListSubheader, Radio} from "@mui/material";
import {selectEnumerates, selectPrimitives} from "store/modules/Semantic";
import {useSelector} from "react-redux";

const PropertyRelationFulfill = (
    {
        items,
        setItems,
        isProperty = true,
        selected,
        item,
    }
) => {
    const {t} = useTranslation();

    const primitives = useSelector(selectPrimitives);
    const enumerates = useSelector(selectEnumerates);

    const field = isProperty ? 'properties' : 'relations';

    const classPrimitive = 'App\\Models\\PrimitiveEnumerate';
    const classEnumerate = 'App\\Models\\Enumerate';

    const getElement = (type) => {
        const element = recursiveFindObject(items, selected);
        const fieldNow = element[field];

        if (type === 'object') {
            return fieldNow.find(el => el === item);
        } else if (type === 'array') {
            return fieldNow;
        }
    };

    const deleteItem = () => {
        const itemsNow = [...items];

        const itemDelete = getElement('array');
        itemDelete.splice(itemDelete.indexOf(item), 1);

        setItems(itemsNow);
    };

    const changeAttributeSelected = (attribute, value) => {
        let itemsNow = [...items];

        const elementNow = getElement('object');
        elementNow[attribute] = value;

        setItems(itemsNow);
    };

    const changeAttributeTypeable = (value) => {
        const split = value.split(".");

        changeAttributeSelected('typeable_type', split[0]);
        changeAttributeSelected('typeable_id', split[1]);
    };

    const getClasses = (array, depth = 1) => {
        const result = [];

        array?.map((item) => {
            result.push({
                item: item,
                depth: depth
            });

            if (item.children.length) {
                result.push(...getClasses(item.children, depth + 1));
            }
        });

        return result;
    };

    const getClassesItems = () => {
        return getClasses(items, 1)?.map((item) => {
            return (
                <MenuItem key={item.item.id} value={item.item.id}>
                    {'-'.repeat(item.depth) + ' ' + item.item.display}
                </MenuItem>
            );
        });
    };

    // get types for both 'primitives' and 'enumerates' for isProperty
    const getTypesGeneric = (types, typeable) => {
        return types.map((type, index) => {
            return (
                <MenuItem key={index} value={`${typeable}.${type.id}`}>
                    {type.type}
                </MenuItem>
            );
        });
    };

    const isFromRoot = () => {
        const test = recursiveFindObjectWithHistory(items, selected);

        return test && test[1].length === 0;
    };

    const getRequired = () => {
        let mainPropertyId;
        const [object, history] = recursiveFindObjectWithHistory(items, selected);

        if (history.length === 0) {
            // it is a property/relation from the root class
            mainPropertyId = object.mainPropertyId;
        } else {
            // it is a property from a inner class
            const obj = history[0];
            mainPropertyId = obj.mainPropertyId;
        }

        return mainPropertyId === item.id;
    };

    const setRequired = () => {
        const itemsNow = [...items];

        const [object, history] = recursiveFindObjectWithHistory(itemsNow, selected);

        if (history.length === 0) {
            // it is a property/relation from the root class
            object.mainPropertyId = item.id;
        } else {
            // it is a property from a inner class
            history[0].mainPropertyId = item.id;
        }

        setItems(itemsNow);
    };

    return (
        <Grid item sx={{border: '1px solid #d9d9d9', marginTop: '5px', padding: '10px', borderRadius: '4px'}}>
            {
                isProperty && isFromRoot() &&
                <Radio
                    checked={getRequired()}
                    onChange={() => setRequired()}
                />
            }

            <TextField
                label={t('semantic.field.display')}
                variant={'outlined'}
                value={item.display}
                className={'margin8 widthInput'}
                onChange={(e) => changeAttributeSelected('display', e.target.value)}
            />

            <TextField
                label={t('semantic.field.sameAs')}
                variant={'outlined'}
                value={item.sameAs}
                className={'margin8 widthInput'}
                onChange={(e) => changeAttributeSelected('sameAs', e.target.value)}
            />

            {isProperty &&
                <FormControl variant={'outlined'}>
                    <InputLabel>
                        {t('semantic.field.type')}
                    </InputLabel>
                    <Select
                        label={t('semantic.field.type')}
                        value={`${item.typeable_type}.${item.typeable_id}`}
                        className={'margin8 widthInput'}
                        onChange={(e) => changeAttributeTypeable(e.target.value)}
                    >
                        <ListSubheader>{t('semantic.field.primitives')}</ListSubheader>
                        {getTypesGeneric(primitives, classPrimitive)}

                        <ListSubheader>{t('semantic.field.enumerates')}</ListSubheader>
                        {getTypesGeneric(enumerates, classEnumerate)}
                    </Select>
                </FormControl>
            }

            {!isProperty &&
                <FormControl variant={'outlined'}>
                    <InputLabel>
                        {t('semantic.field.range')}
                    </InputLabel>
                    <Select
                        label={t('semantic.field.range')}
                        value={item.range_id}
                        className={'margin8 widthInput'}
                        onChange={(e) => changeAttributeSelected('range_id', e.target.value)}
                    >
                        {getClassesItems()}
                    </Select>
                </FormControl>
            }

            <TooltipIcon tooltipText={'general.delete'} icon={'delete'} onClick={deleteItem}/>
        </Grid>
    );
};

export default PropertyRelationFulfill;
