import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import utils, {isProtectedUser} from "utils/utils";
import MenuProtected from "components/protected/MenuProtected";
import loginApi from "api/routes/login";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectError, setError} from "store/modules/Error";
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from "@mui/material/MenuItem";
import AvatarUser from "components/AvatarUser";
import {Stack} from "@mui/material";
import {selectDialog} from "store/modules/ConfirmDialog";
import ConfirmDialog from "components/ConfirmDialog";
import useAuth from "hooks/useAuth";


/**
 * Errors are shown by Templates, handled by API and Redux Store.
 * The delete confirm dialog is also show by Templates, handled by Redux Store.
 * @returns {JSX.Element} Element representing general student/teacher template
 * @constructor
 */
const Template = () => {
    const {t} = useTranslation();
    const user = useAuth();
    const navigate = useNavigate();

    let location = useLocation();
    const dispatch = useDispatch();
    const error = useSelector(selectError);
    const confirmDialog = useSelector(selectDialog);

    // navbar behaviour
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    async function logout(e) {
        e.preventDefault();
        await loginApi.logout();
        navigate("/");
    }

    useEffect(() => {
        dispatch(setError(""));
    }, [location]);

    return (
        <>
            <AppBar position="static" color={"primary"}>
                <Container maxWidth={'xl'}>
                    <Toolbar style={{justifyContent: 'space-between'}}>
                        <Link to={`/${utils.rolesNames[user.role]}`}>
                            <span className={'logoFont logoHeaderSize white'}>ld annot</span>
                        </Link>

                        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                {isProtectedUser(user) &&
                                    <MenuProtected isMobile handleCloseNavMenu={handleCloseNavMenu} logout={logout}/>
                                }
                            </Menu>
                        </Box>

                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}></Box>

                        <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
                            <Stack direction={'row'}>
                                {isProtectedUser(user) &&
                                    <MenuProtected/>
                                }

                                <div
                                    id={'basic-button'}
                                    aria-controls={menuOpen ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={menuOpen ? 'true' : undefined}
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                    className={'pointer fade'}
                                >
                                    <AvatarUser className={'pointer fade'}/>
                                </div>
                            </Stack>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={() => setAnchorEl(null)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button'
                                }}
                            >
                                <MenuItem onClick={logout}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container className={'ar-container'}>
                <Outlet/>

                <ConfirmDialog
                    open={confirmDialog.open}
                    title={confirmDialog.title}
                    contentText={confirmDialog.contentText}
                    item={confirmDialog.item}
                    confirmText={confirmDialog.confirmText}
                    onClose={confirmDialog.onClose}
                    param={confirmDialog.param}
                />

                <small className={'danger'}>{error.message ? error.message : error}</small>
            </Container>
        </>
    );
};

export default Template;
