import React, {useState} from 'react';
import {Home, Refresh, Visibility, VisibilityOff} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import TooltipIcon from "components/TooltipIcon";
import {useDispatch, useSelector} from "react-redux";
import {
    getAnnotations,
    hideAnnotations,
    selectIsAnnotating,
    selectIsHidden,
    showAnnotations
} from "store/modules/Annotations";
import {ACTION, Can} from 'ability';
import {Drawer, List} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import SummarizeIcon from '@mui/icons-material/Summarize';
import ReportAnnotation from "components/container/ReportAnnotations/ReportAnnotation";
import {useNavigate} from "react-router-dom";

const MenuContainer = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [reportOpen, setReportOpen] = useState(false);
    const [reportAnnotationOpen, setReportAnnotationOpen] = useState(false);

    const isAnnotating = useSelector(selectIsAnnotating);
    const isHidden = useSelector(selectIsHidden);
    const dispatch = useDispatch();

    const target = window.location.href;

    const annotationReportItems = [
        {
            icon: <Home fontSize={'small'} />,
            title: 'general.home',
            action: () => {
                navigate('/');
            },
            item: "home"
        },
        {
            icon: <SummarizeIcon fontSize={'small'}/>,
            title: "container.report",
            action: () => {
                setReportAnnotationOpen(true);
                setReportOpen(false);
            },
            item: "annotationReport"
        }
    ];

    const buttons = [
        {
            icon: <Refresh fontSize={'small'}/>,
            text: t('general.refresh'),
            action: () => {
                dispatch(getAnnotations(target));
            },
        },
        {
            icon: !isHidden ? <Visibility fontSize={'small'}/> : <VisibilityOff fontSize={'small'}/>,
            text: t(`container.${!isHidden ? 'hide' : 'show'}Annotations`),
            action: () => {
                dispatch(isHidden ? showAnnotations() : hideAnnotations());
            }
        }
    ];

    return (
        <>
            <AppBar position="fixed" color="primary" sx={{top: 0, bottom: 'auto', marginBottom: '50px', zIndex: '2'}}>
                <Toolbar>
                    <TooltipIcon
                        icon={'menu_icon'}
                        color={'secondary'}
                        tooltipText={'container.annotationReport'}
                        onClick={() => setReportOpen(true)}
                    />

                    <Box sx={{flexGrow: 1}}/>

                    {buttons.map((button, i) => (
                        <TooltipIcon
                            key={i}
                            tooltipText={button.text}
                            icon={button.icon}
                            onClick={button.action}
                            color={'secondary'}
                            disabled={isAnnotating}
                        />
                    ))}
                </Toolbar>
            </AppBar>

            <Drawer open={reportOpen} onClose={() => setReportOpen(false)}>
                <Box sx={{width: 250}} role={'presentation'} onClick={() => setReportOpen(false)}>
                    <List>
                        {annotationReportItems.map((item, index) => (
                            <Can I={ACTION.READ} this={item.item} key={index}>
                                <ListItemButton onClick={item.action}>
                                    <ListItemIcon color={'secondary'}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={t(item.title)}/>
                                </ListItemButton>
                            </Can>
                        ))}
                    </List>
                </Box>
            </Drawer>

            <Can I={ACTION.READ} a={'annotationReport'}>
                {/* Report annotation */}
                <ReportAnnotation
                    open={reportAnnotationOpen}
                    setOpen={(flag) => setReportAnnotationOpen(flag)}
                />
            </Can>
        </>
    );
};

export default MenuContainer;
