export default {
    translations: {
        brand: {
            name: "LDAnnot",
            description: "Ferramenta de anotação colaborativa em conteúdos digitais voltados ao ensino de línguas",
        },
        auth: {
            login: "Entrar",
            createAccount: "Criar Conta",
            alreadyHaveAccount: "Já tenho uma conta",
            backIndex: "Voltar para a página inicial",
            logout: "Sair",
            roleFailed: "Parece que seu nível de perfil não existe. Contacte um administrador.",
            forgotPassword: "Esqueci minha senha",
            forgot: {
                instructionsEmail: "As instruções de recuperação de acesso foram enviadas para {{email}}"
            },
            confirm: {
                title: "Defina uma senha de acesso",
                passwordSet: "Senha definida com sucesso, você será redirecionado para a página de login",
                confirmPassword: "Confirme a senha",
                setPassword: "Definir senha",
                passwordDiff: "As senhas não conferem"
            },
            create: {
                title: "Criar usuário",
                line0: "É muito bom ter você por aqui!",
                line1: "Você vai receber um e-mail para confirmar sua inscrição, até mais!",
                fullName: "Nome completo"
            }
        },
        common: {
            template: {
                logo: "Logo LDAnnot"
            }
        },
        teacher: {
            exportAnnotations: "Exportar anotações",
        },
        stats: {
            stats_free: "Estatística livre",
            stats_semantic: "Estatística semântica",
            telemetry: "Telemetria",
        },
        activities: {
            title: "Atividades",
            titleNew: "atividade",
            newActivity: "Nova atividade",
            deleteActivity: "Excluir atividade",
            create: {
                nameActivity: "Nome da atividade",
                repeatTitleAnnotations: "Repetir titulo do documento no quadro de anotações",
                knowledgeBase: "Base de conhecimento",
                knowledgeBaseShare: "Bases de conhecimento são compartilhadas entre os usuários da atividade",
                singleKnowledgeBase: "Uma base de conhecimento para todos os documentos",
                includeClassesValidation: "Incluir classes na validação",
                digitalLibraryDocuments: "Documentos da biblioteca digital",
                yourDocuments: "Seus documentos",
                permissions: "Permissões",
                attributes: {
                    createAnnotations: "Criar Anotações",
                    editAnnotations: "Editar Anotações",
                    createFree: "Criar anotações Livre",
                    createSemantic: "Criar anotações semânticas",
                    createReplies: "Criar respostas",
                    editReplies: "Editar respostas",
                    visibleEverybody: "Fazer anotações com visibilidade 'pública'",
                    visibleGroups: "Fazer anotações com visibilidade 'moderador'",
                    visiblePrivate: "Fazer anotações com visibilidade 'privada'",
                    seeAllSemantic: "Ver os outros membros da base de conhecimento",
                    filterByUser: "Permitir a filtragem de anotações livres por usuário"
                }
            }
        },
        users: {
            title: "Usuários",
            singleTitle: "usuário",
            removeCourse: "usuário deste curso",
            addUsers: {
                title: "Adicionar usuários",
                search: "Pesquisa por usuários",
                role: "Papel"
            },
            read: {
                title: "Gerenciamento de usuários",
                table: {
                    id: "ID",
                    name: "Nome",
                    email: "E-mail",
                    role: "Papel"
                }
            },
            edit: {
                title: "Editar usuário"
            },
            changeItself: "Você alterou o seu próprio usuário, por isso será feito logout do sistema!"
        },
        courses: {
            users: {
                title: "Usuários do curso"
            },
            title: "Cursos",
            titleNew: "curso",
            create: {
                nameCourse: "Nome do curso",
            },
            edit: "Renomear curso"
        },
        documents: {
            title: "Documentos",
            singleTitle: "documento",
            singleTitleCaps: "Documento",
            sameAs: "Anotação semântica (same as)",
            path: "Caminho",
            author: "Autor",
        },
        semantic: {
            title: "Semântico",
            titleShow: "Esquemas semânticos",
            singleTitle: "Esquema semântico",
            schemeDefinition: "Edição de esquema",
            addTitle: "Definição de esquema semântico",
            addSibling: "Adicionar conceito",
            addChild: "Adicionar filho",
            typeClass: "Digite o conceito aqui",
            property: "Propriedade",
            properties: "Propriedades",
            relations: "Relações",
            relation: "Relação",
            classDefinition: "Definição de classe",
            semanticClass: "Classe semântica",
            field: {
                type: "Tipo de campo",
                display: "Display",
                sameAs: "Definição semântica (sameAs)",
                range: "Intervalo de relação",
                primitives: "Tipos primitivos",
                enumerates: "Enumerados",
                enumerateName: "Nome do enumerado",
                enumerate: "enumerado",
            },
            setRequiredError: "Todas as classes raízes devem ter uma propriedade principal. A(s) seguinte(s) não tem: {{display}}",
        },
        general: {
            name: "Nome",
            moderator: "Moderador",
            teacher: "Professor",
            student: "Aluno",
            create: "Criar",
            add: "Adicionar",
            edit: "Editar",
            delete: "Excluir",
            reset: "Redefinir",
            email: "E-mail",
            password: "Senha",
            alert: "Ops, não foi possível concluir o solicitação",
            confirmDelete: "Você deseja realmente excluir?",
            confirmDeleteText: "Você deseja realmente excluir este(a) {{item}}?",
            confirmDuplicate: "Você deseja realmente duplicar?",
            confirmDuplicateText: "Você deseja realmente duplicar este(a) {{item}}?",
            fulfillAll: "Você deve preencher todos os campos",
            title: "Título",
            actions: "Ações",
            item: "Item",
            public: "Público",
            private: "Privado",
            moderate: "Moderador",
            description: "Descrição",
            notValidUrl: "URL inválida para: {{url}}",
            save: "Salvar",
            cancel: "Cancelar",
            index: "Índice",
            duplicate: "Duplicar",
            reply: "Responder",
            visibility: "Visibilidade",
            comment: "Comentário",
            all: "Todas",
            refresh: "Atualizar",
            home: "Página inicial",
            copy: "Copiar",
            key: "Chave",
            secret: "Segredo",
            close: "Fechar"
        },
        genericRead: {
            notFound: "Nenhum {{title}} encontrado"
        },
        roles: {
            owner: "Criador",
            moderator: "Moderador",
            student: "Aluno",
            teacher: "Professor",
            admin: "Administrador"
        },
        admin: {
            users: "Usuários",
        },
        selectMultiple: {
            leftTitle: "Disponível",
            rightTitle: "Selecionado"
        },
        dragDropFile: {
            title: "Arraste ou clique para selecionar",
            hoverTitle: "Arraste aqui",
            invalidFileType: "Tipo de arquivo inválido"
        },
        boolean: {
            true: "Sim",
            false: "Não"
        },
        container: {
            collapse: "Recolher",
            expand: "Expandir",
            hideAnnotations: "Esconder anotações",
            showAnnotations: "Mostrar anotações",
            freeAnnotation: "Anotação livre",
            semanticAnnotation: "Anotação semântica",
            deleteInstance: "Excluir instância",
            annotationReport: "Relatório de anotações",
            report: "Relatório",
            annotationType: "Tipo de anotação",
            freeType: "Tipo livre",
            resource: "Recurso semântico",
            user: "Usuário"
        },
        annotation: {
            freeAnnotationType: "Tipo de anotação livre",
            purpose: {
                commenting: "Comentário",
                highlighting: "Recomendação",
                moderating: "Tarefa",
                describing: "Exemplo",
                editing: "Explicação",
                questioning: "Questão",
                bookmarking: "Ver também",
                classifying: "Semântica"
            },
            seeInstance: "Ver instância",
            addResource: "Adicionar recurso",
            type: "Tipo",
            seize: "Capturar",
            semanticAnnotation: {
                steps: {
                    classChoose: "Escolha uma classe",
                    propertyFulfill: "Preencha as propriedades",
                    back: "Passo anterior",
                    next: "Passo seguinte"
                }
            },
            annotatedText: "Texto da anotação",
            goToAnnotation: "Ir para anotação"
        }
    },
};
